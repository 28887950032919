// https://ant.design/docs/react/customize-theme#How-to-avoid-modifying-global-styles
@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/themes/default.less';

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin-top: 0;
  margin-bottom: 0;
}

