@Orange: #F55523;
@DarkestOrange: #943500;
@DarkerOrange: #BF4713;
@LightOrange: #F46E4A;
@LighterOrange: #FF9A80;
@LightestOrange: #FFD5C4;
@LightestOrange2: #FFF2EC;
@Cream: #FCF9F5;
@LightCream: #FEFCFA;

@Blue: #0B28BD;
@DarkestBlue: #05124C;
@DarkerBlue: #06317C;
@LighterBlue: #7990F2;
@LightBlue: #3961E2;
@LightestBlue: #C2D2F2;

@Black: #000;
@Black14: #141414;
@Black1F: #1F1F1F;
@Black2C: #2C2C2C;
@Black43: #434343;
@Black59: #595959;
@Black8C: #8C8C8C;

@Gray: #BFBFBF;
@GrayF0: #F0F0F0;
@GrayF5: #F5F5F5;
@GrayD9: #D9D9D9;
@GrayFA: #FAFAFA;
@White: #FFF;

@Error: #FF3B30;
@Error1: #FFF0EF;
@Success: #1DD1A1;
@Success1: #EDFCF8;
@Warning: #FFCC00;
@Warning1: #FFFBEB;
@Link: #007AFF;
@Link1: #F0F6FF;

@None: transparent;

@Primary: @Orange;
@PrimaryDarkest: @DarkestOrange;
@PrimaryDarker: @DarkerOrange;
@PrimaryLight: @LightOrange;
@PrimaryLighter: @LighterOrange;
@PrimaryLightest: @LightestOrange;
@PrimaryLightest2: @LightestOrange2;
@PrimaryCream: @Cream;
@PrimaryCream2: @LightCream;

@Secondary: @Blue;
@SecondaryDarkest: @DarkestBlue;
@SecondaryDarker: @DarkerBlue;
@SecondaryLight: @LightBlue;
@SecondaryLighter: @LighterBlue;
@SecondaryLighter2: #E7EDFF;
@SecondaryLightest: @LightestBlue;

@Neutral: @Black;
@Neutral12: @Black14;
@Neutral11: @Black1F;
@Neutral10: @Black2C;
@Neutral9: @Black43;
@Neutral8: @Black59;
@Neutral7: @Black8C;
@Neutral6: @Gray;
@Neutral5: @GrayD9;
@Neutral4: @GrayF0;
@Neutral3: @GrayF5;
@Neutral2: @GrayFA;
@Neutral1: @White;

@shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.05), 6px 10px 10px rgba(0, 0, 0, 0.03);
